import * as React from 'react';
import * as firebase from 'firebase/app';
import * as Auth from 'firebase/auth';
import logo from '../assets/images/qolor_logo_web.png';
import logo_2x from '../assets/images/qolor_logo_web@2x.png';
import logo_3x from '../assets/images/qolor_logo_web@3x.png';
import {
  ChangeEvent,
  CSSProperties,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isValidPassword from '../utils/isValidPassword';
import { navigate, PageProps } from 'gatsby';
import {
  resetPassword,
  verifyPasswordResetCode,
} from '../utils/firebaseActions';

// styles
const pageStyles: CSSProperties = {
  color: '#4F6366',
  padding: '20px 20px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
};

const logoStyle: CSSProperties = {
  marginBottom: 40,
  alignSelf: 'center',
};

const headingStyles: CSSProperties = {
  fontSize: 24,
  lineHeight: '41px',
  textAlign: 'center',
};

const settingRowStyle: CSSProperties = {
  fontSize: 16,
  lineHeight: '23px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 360,
  margin: '24px auto',
};

const formLabelStyle: CSSProperties = {
  display: 'inline-block',
  fontWeight: 600,
  marginBottom: 6,
};

const formInputStyle: CSSProperties = {
  height: 24,
  padding: '8px 16px',
  borderRadius: 4,
  border: `1px solid #00000029`,
};

const passwordDescription: CSSProperties = {
  fontSize: 12,
  fontWeight: 300,
  lineHeight: '20px',
};

const formErrorLabelStyle: CSSProperties = {
  fontSize: 12,
  fontWeight: 300,
  lineHeight: '20px',
  color: '#FF79AF',
};

const submitButtonStyle: CSSProperties = {
  height: 48,
  borderRadius: 24,
  border: 'unset',
  background: `linear-gradient(101deg,#FFF77F,#FF79AF)`,
  fontSize: 16,
  fontWeight: 600,
  color: '#FFFFFF',
};

const disabledButtonStyle: CSSProperties = {
  height: 48,
  borderRadius: 24,
  border: 'unset',
  background: '#D8DFE3',
  fontSize: 16,
  fontWeight: 600,
  color: '#B8C0C3',
};

const ResetPasswordPage: FC<PageProps> = ({ location }) => {
  const { oobCode } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      mode: params.get('mode'),
      oobCode: params.get('oobCode'),
    };
  }, [location.search]);

  useEffect(() => {
    const auth = Auth.getAuth(firebase.getApp());
    verifyPasswordResetCode(auth, oobCode).catch((e) => {
      console.warn(e);
      navigate('/resetPasswordError/', { replace: true });
    });
  }, []);

  const [values, setValues] = useState<{
    password?: string;
    passwordValidation?: string;
  }>({});

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    setValid(
      !!values.password &&
        !!values.passwordValidation &&
        isValidPassword(values.password),
    );
  }, [values]);

  const [errors, setErrors] = useState<
    Partial<Record<keyof typeof values, string>>
  >({});

  const validate = useCallback(
    (target?: keyof typeof values): boolean => {
      let valid = true;
      if (!target || target === 'password') {
        if (!values.password || !isValidPassword(values.password)) {
          valid = false;
          setErrors((prev) => ({
            ...prev,
            password: '条件を満たすパスワードを入力してください',
          }));
        } else {
          setErrors((prev) => ({ ...prev, password: undefined }));
        }
      }
      if (!target || target === 'passwordValidation') {
        if (
          !values.passwordValidation ||
          values.password !== values.passwordValidation
        ) {
          console.log('validation', values.password, values.passwordValidation);
          valid = false;
          setErrors((prev) => ({
            ...prev,
            passwordValidation: 'パスワードが一致しません',
          }));
        } else {
          setErrors((prev) => ({ ...prev, passwordValidation: undefined }));
        }
      }
      return valid;
    },
    [values],
  );

  const handleChange =
    (inputType: keyof typeof values) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event?.target?.value || '';
      setErrors((prev) => ({ ...prev, [inputType]: undefined }));
      setValues((prev) => ({ ...prev, [inputType]: newValue }));
    };

  const handleBlur = (inputType: keyof typeof values) => () => {
    validate(inputType);
  };

  const [processing, setProcessing] = useState<boolean>(false);

  const handleClickSubmit = useCallback(
    (event: MouseEvent) => {
      if (!values.password || !validate() || processing) {
        return;
      }
      setProcessing(true);
      const auth = Auth.getAuth(firebase.getApp());
      resetPassword(auth, oobCode, values.password)
        .then(() => {
          navigate('/resetCompleted/');
        })
        .catch((e) => {
          navigate('/resetPasswordError/');
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [values, oobCode, processing],
  );

  return (
    <main style={pageStyles}>
      <title>{'新しいパスワードを設定してください - Qolor'}</title>
      <img
        style={logoStyle}
        srcSet={`${logo} 1x, ${logo_2x} 2x, ${logo_3x} 3x`}
        alt="Qolor Logo"
        width={70.527}
        height={120}
      />
      <h1 style={headingStyles}>{'新しいパスワードを設定してください'}</h1>
      <form>
        <div style={settingRowStyle}>
          <label style={formLabelStyle}>{'新しいパスワード'}</label>
          <span style={passwordDescription}>
            {'数字、英小文字、英大文字の3種類を必ず含めて8文字以上'}
          </span>
          <input
            id={'password'}
            style={formInputStyle}
            type={'password'}
            disabled={processing}
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password || ''}
          />
          {!!errors.password && (
            <span style={formErrorLabelStyle}>{errors.password}</span>
          )}
        </div>
        <div style={settingRowStyle}>
          <label style={formLabelStyle}>{'パスワード再確認'}</label>
          <input
            id={'confirm-password'}
            style={formInputStyle}
            type={'password'}
            disabled={processing}
            onChange={handleChange('passwordValidation')}
            onBlur={handleBlur('passwordValidation')}
            value={values.passwordValidation || ''}
          />
          {!!errors.passwordValidation && (
            <span style={formErrorLabelStyle}>{errors.passwordValidation}</span>
          )}
        </div>
        <div style={settingRowStyle}>
          <button
            type={'button'}
            style={isValid ? submitButtonStyle : disabledButtonStyle}
            disabled={!isValid}
            onClick={handleClickSubmit}
          >
            {'パスワードを保存'}
          </button>
        </div>
      </form>
    </main>
  );
};

export default ResetPasswordPage;
