import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();

const isValidPassword = (passwd: string) => {
  return schema.validate(passwd) === true;
};

export default isValidPassword;
